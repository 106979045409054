import React, { useEffect } from 'react';
import logotipo from '@/../../public/assets/img/logotipo.png'
import AppBanner from "../Banner/Appbanner";
import $ from 'jquery'; 

const Appheader = () => {
  useEffect(() => {
    // Seleciona elementos
    const navbarlinks = $('#navbar .scrollto');
    const selectHeader = $('#header');

    // Função para ativar os links da barra de navegação com base na rolagem
      const navbarlinksActive = () => {
      const position = $(window).scrollTop() + 200;
      navbarlinks.each(function() {
        if (!this.hash) return;
        const section = $(this.hash);
        if (!section.length) return;
        if (position >= section.offset().top && position <= (section.offset().top + section.outerHeight())) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      });
    };

    // Função para rolar para um elemento com deslocamento de cabeçalho
    const scrollto = (el) => {
      const header = $('#header');
      const offset = header.outerHeight();
      const elementPos = $(el).offset().top;
      $('html, body').animate({
        scrollTop: elementPos - offset
      }, 'smooth');
    };

    // Função para adicionar a classe .header-scrolled ao cabeçalho quando a página é rolada
    const headerScrolled = () => {
      if ($(window).scrollTop() > 100) {
        selectHeader.addClass('header-scrolled');
      } else {
        selectHeader.removeClass('header-scrolled');
      }
    };

    // Adiciona ouvintes de eventos
    $(window).on('load', () => {
      navbarlinksActive();
      headerScrolled();
    });

    $(document).on('scroll', () => {
      navbarlinksActive();
      headerScrolled();
    });

    // Adiciona e remove ouvintes de eventos de clique
    $(document).on('click', '.mobile-nav-toggle', function() {
      $('#navbar').toggleClass('navbar-mobile');
      $(this).toggleClass('bi-list bi-x');
    });

    $(document).on('click', '.navbar .dropdown > a', function(e) {
      if ($('#navbar').hasClass('navbar-mobile')) {
        e.preventDefault();
        $(this).next().toggleClass('dropdown-active');
      }
    });

    $(document).on('click', '.scrollto', function(e) {
      if ($(this.hash).length) {
        e.preventDefault();
        const navbar = $('#navbar');
        if (navbar.hasClass('navbar-mobile')) {
          navbar.removeClass('navbar-mobile');
          $('.mobile-nav-toggle').toggleClass('bi-list bi-x');
        }
        scrollto(this.hash);
      }
    });

    // Função de limpeza para remover ouvintes de eventos quando o componente for desmontado
    return () => {
      $(window).off('load');
      $(document).off('scroll');
      $(document).off('click', '.mobile-nav-toggle');
      $(document).off('click', '.navbar .dropdown > a');
      $(document).off('click', '.scrollto');
    };
  }, []);
    return(
      <>
      {/* ======= Header ======= */}
      <header id="header" className="fixed-top d-flex align-items-center  header-transparent ">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1>
            <a href="/">
            <img src={logotipo} alt="logo" />  
            </a>
            </h1>
            {/* Uncomment below if you prefer to use an image logo */}
            {/* <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
          </div>
          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="nav-link scrollto active" href="/">Home</a></li>
              <li><a className="nav-link scrollto" href="#about">Sobre Nós</a></li>
              <li><a className="nav-link scrollto" href="#services">Cursos</a></li>
              <li><a className="nav-link scrollto " href="#portfolio">Destaques</a></li>
              <li><a className="nav-link scrollto" href="#pricing">Preçarios</a></li>
              <li><a className="nav-link scrollto" href="#team">Professores</a></li>
              <li className="dropdown"><a href=" #"><span>Instituição</span> <i className="bi bi-chevron-down" /></a>
                <ul>
                  <li><a href="/laboratorio">Infraestrutura e Laboratórios</a></li>
                  <li><a href="/docentes">Corpo Docente</a></li>
                  <li><a href="/estagios">Estágios</a></li>
                </ul>
              </li>
              <li><a className="nav-link scrollto" href="#contact">Contacto</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
      <AppBanner />
      </>
    )
};
export default Appheader
