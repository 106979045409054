import React from 'react'

function Appsectionpricing() {
  return (
    <div>
                {/* ======= Pricing Section ======= */}
                <section id="pricing" className="pricing">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Estrutura de Preços</h2>
              <p>Nossos preços competitivos</p>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="box" data-aos="zoom-in">
                  <h3>10ª Classe</h3>
                  <h4><sup>kz</sup>22.500<span> / mês</span></h4>
                  <ul>
                    <li>Excelentes Professores</li>
                    <li>Qualidade no Ensino </li>
                    <li>Salas Climatizadas</li>
                  </ul>
                  <div className="btn-wrap">
                    <a href="/preços" className="btn-buy">Saiba mais</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
                <div className="box featured" data-aos="zoom-in" data-aos-delay={100}>
                  <h3>11ª Classe</h3>
                  <h4><sup>kz</sup>22.500<span> / mês</span></h4>
                  <ul>
                    <li>Excelentes Professores</li>
                    <li>Qualidade no Ensino </li>
                    <li>Salas Climatizadas</li>
                  </ul>
                  <div className="btn-wrap">
                    <a href="/preços" className="btn-buy">Saiba mais</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
                <div className="box" data-aos="zoom-in" data-aos-delay={200}>
                  <h3>12ª Classe</h3>
                  <h4><sup>kz</sup>22.500<span> / mês</span></h4>
                  <ul>
                    <li>Excelentes Professores</li>
                    <li>Qualidade no Ensino</li>
                    <li>Salas Climatizadas</li>
                  </ul>
                  <div className="btn-wrap">
                    <a href="/preços" className="btn-buy">Saiba mais</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
                <div className="box" data-aos="zoom-in" data-aos-delay={300}>
                  <span className="advanced">Último nível</span>
                  <h3>13ª Classe</h3>
                  <h4><sup>kz</sup>34.000<span> / mês</span></h4>
                  <ul>
                    <li>Excelentes Professores</li>
                    <li>Qualidade no Ensino</li>
                    <li>Salas Climatizadas</li>
                  </ul>
                  <div className="btn-wrap">
                    <a href="/preços" className="btn-buy">Saiba mais</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{/* End Pricing Section */}
    </div>
  )
}

export default Appsectionpricing