import React from 'react'
import Appheaderpage from '../components/Layout/Appheaderpage'
import Appfooter from '../components/Layout/Appfooter'




function Applaboratory() {
  return (
    <div>
      <Appheaderpage/>
      <main id="main">
      <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Estrutura dos Laboratórios</h2>
          <ol>
            <li><a style={{color:'#AB9458'}} href="/">Home</a></li>
            <li>Laboratório</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    {/* ======= Portfolio Details Section ======= */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-8">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Nossos laboratórios</h2>
              <p> A Prática que Forma Profissionais de Excelência</p>
            </div>
            <div className="row content" data-aos="fade-up">
              <div className="col-lg-6">
                <p>
                Na nossa instituição, sabemos que a formação de um enfermeiro de qualidade vai além da teoria. Por isso, oferecemos aos nossos alunos espaços de aprendizado prático de última geração em nossos Laboratórios de Enfermagem. <br /><br />Equipados com tecnologias de ponta e simuladores de alta fidelidade, nossos laboratórios são ambientes inovadores, projetados para proporcionar experiências que vão fortalecer as habilidades práticas essenciais para o exercício da profissão. <br />
                </p>
                <div style={{borderRadius:'22px'}} className="portfolio-info">
              <h3>O que vai encontrar</h3>
              <ul >
                <li><strong><i className="ri-check-double-line" style={{color:'#AB9458'}}/></strong>  Equipamentos e instrumentos de alta qualidade </li>
                <li><strong><i className="ri-check-double-line" style={{color:'#AB9458'}}/></strong>  Tecnologia de Ponta</li>
                <li><strong><i className="ri-check-double-line" style={{color:'#AB9458'}}/></strong>  Simuladores de Alta Fidelidade</li>
              </ul>
            </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-3">
                <h2>Seu Futuro Começa Aqui</h2>
                <p>
                No nosso Instituto, não queremos apenas formar estudantes – queremos formar líderes no campo das ciências. Líderes que, com conhecimento prático e teórico, estarão prontos para impactar o mundo de forma positiva. Nossos laboratórios são projetados para prepará-lo para os desafios do ensino superior, para o mercado de trabalho e, acima de tudo, para a vida.
                </p>
                <div className="portfolio-descriptionlab ">
                <h2>Porque os Nossos Laboratórios São Diferentes?</h2>
                <p>
                Imagine-se operando um microscópio de alta tecnologia, observando as estruturas celulares como nunca antes. Imagine manipular substâncias químicas em reações que você mesmo idealizou ou montando um robô capaz de realizar tarefas complexas. Em nossos laboratórios, isso não é apenas um sonho – é uma realidade ao alcance das suas mãos.
                </p>
                <h3>Aqui, você vai:</h3>
                <ul >
                <li><strong></strong> Experimentar e Vivenciar – Cada experiência prática é uma oportunidade de aprender de forma profunda e significativa. Em vez de apenas ler sobre ciência, você vai fazer ciência. </li>
                <li><strong></strong>  Desafiar-se a Pensar de Forma Criativa – Usando simuladores, robótica e até realidade aumentada, você desenvolverá soluções inovadoras para problemas reais.</li>
                <li><strong></strong>  Explorar Suas Paixões – De biologia a física, de química a robótica, nossos laboratórios oferecem um leque de oportunidades para que você se conecte com as áreas que mais lhe fascinam.</li>
                <li><strong></strong>  Aprender com Tecnologia de Ponta – Ferramentas modernas e equipamentos de última geração garantem que você tenha o que há de melhor para se aprofundar em sua jornada científica..</li>
              </ul>
                <br/>
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/lab02.jpg" className="img-fluid" alt='Enfermagem' />
                </div>
                <br />
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-4">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </section>{/* End Portfolio Details Section */}
    </main>
    <Appfooter />
    </div>
  )
}

export default Applaboratory