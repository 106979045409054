import React from 'react'
import Appheaderpage from '../components/Layout/Appheaderpage'
import Appfooter from '../components/Layout/Appfooter'




function Appestagios() {
  return (
    <div>
      <Appheaderpage/>
      <main id="main">
      <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Estágios</h2>
          <ol>
            <li><a style={{color:'#AB9458'}} href="/">Home</a></li>
            <li>Estágio</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    {/* ======= Portfolio Details Section ======= */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4">
          <div className="">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Conheça os nossos estágios</h2>
              <p> Oportunidade de Crescimento e Aprendizado</p>
            </div>
            <div className="row content" data-aos="fade-up">
              <div className=" pt-4 pt-lg-0">
                <p>
                Oferecemos uma experiência única para estudantes que desejam colocar em prática o que aprenderam na teoria, adquirindo habilidades profissionais em um ambiente dinâmico e desafiador. Com foco no desenvolvimento pessoal e acadêmico, nossa sessão de estágio proporciona uma imersão no mercado de trabalho, permitindo que os estagiários vivenciem a rotina de uma empresa de alto nível e construam uma base sólida para o futuro profissional.
                </p>
                <ul >
                <li><strong>Desenvolvimento Prático:</strong> Coloque em prática os conhecimentos adquiridos durante a graduação em projetos reais e impactantes.</li>
                <li><strong>Mentoria Especializada:</strong> Acompanhamento próximo de profissionais experientes que irão orientar e impulsionar sua carreira.</li>
                <li><strong>Aprendizado Constante:</strong> Acesso a treinamentos, workshops e feedback contínuo para aprimorar suas habilidades técnicas e comportamentais.</li>
                <li><strong>Networking Profissional:</strong> Conecte-se com colegas de diversas áreas, criando uma rede de contatos valiosa para sua jornada profissional.</li>
                <li><strong>Experiência Reconhecida:</strong> Ao final do estágio, você contará com um certificado que comprova sua vivência e contribuições no mercado</li>

              </ul>
                <div className="">
                <h2>Quem se beneficia do Estágio? </h2>
                <p>
                Para todos dos estudantes do instituto, afim de buscarem vivenciar o dia a dia de uma empresa e se destacar no mercado de trabalho, preparando-se para os desafios de uma carreira de sucesso
                </p>
                <br/>
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/estagio01.jpg" className="img-fluid" alt='Enfermagem' />
                </div>
                <br />
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>{/* End Portfolio Details Section */}
    </main>
    <Appfooter />
    </div>
  )
}

export default Appestagios