import React from 'react'

function Appsectionteam() {
  return (
    <div>
                {/* ======= Team Section ======= */}
                <section id="team" className="team">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Professores</h2>
              <p>Nossa equipe trabalhadora</p>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up">
                  <div className="member-img">
                    <img src="assets/img/team-1.jpg" className="img-fluid" alt='' />
                  </div>
                  <div className="member-info">
                    <h4>Telma Rodrigues</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up" data-aos-delay={100}>
                  <div className="member-img">
                    <img src="assets/img/team-2.jpg" className="img-fluid" alt='' />
                  </div>
                  <div className="member-info">
                    <h4>Professor Nicolau Sobrinho</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up" data-aos-delay={200}>
                  <div className="member-img">
                    <img src="assets/img/team-3.jpg" className="img-fluid" alt='' />
                  </div>
                  <div className="member-info">
                    <h4>Professora Amélia</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up" data-aos-delay={300}>
                  <div className="member-img">
                    <img src="assets/img/team-4.jpg" className="img-fluid" alt='' />
                  </div>
                  <div className="member-info">
                    <h4>Professor Dionísio Francisco</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{/* End Team Section */}
    </div>
  )
}

export default Appsectionteam